import axios, { AxiosInstance, AxiosResponse } from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const AUCTION_URL = process.env.AUCTION_URL;

type BaseApiCall = {

    data: any;
    endpoint?: string;

    // This allows the thunk to cancel the API call if the thunk promise is
    // aborted.  This will result in the thunk being rejected and the `.rejected`
    // extra reducer being invoked.  The extra reducer will need to take the `action`
    // parameter and check its value:
    //
    //      if (action?.error?.name === 'AbortError') {
    //          return;
    //      }
    //
    // Mostly an abort should not modify the slice in any way, although there
    // could be exceptions.  Any other error should be handled as an error.
    //
    // While this is primarily provided for use in Redux thunks, this signal
    // is the same as the signal retrieved from an AbortController, so if
    // it were necessary to have a specific use of that, it could be done.
    //
    // See the docs for createAsyncThunk, AbortController, and AbortSignal for
    // more information.
    signal: AbortSignal;
};

const auctionAxiosInstance: AxiosInstance = axios.create({
    baseURL: AUCTION_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 60000,
});

export const auctionApi = async ({ data, endpoint: url, signal }: BaseApiCall): Promise<AxiosResponse<any>> => {
    const fingerprint = (await (await FingerprintJS.load()).get()).visitorId;
    const results = await auctionAxiosInstance({
        data,
        headers: {
            'X-Fingerprint': fingerprint,
        },
        method: 'POST',
        url,
        signal,
    });
    return results;
};
