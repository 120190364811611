import React, { forwardRef, useCallback, useMemo } from 'react';
import Classnames from 'classnames';
import { InputComponentProps, InputModeTypes, InputTypes, ValidatedInput } from './validated-input';
import { useComponentId } from '../../../hooks';
import { convertCentsToDollars, currencyDisplay } from '../../../utils';
import {
    charIsDigit,
    charIsDigitOrPeriod,
    getNumericValue,
    keyboardEventHasModifier,
    setTargetSelection,
} from './input-helpers';

export interface MonetaryInputProps extends InputComponentProps {
    currencyType: string;
    minAmount?: number;
    maxAmount?: number;
    increment?: number;
    shouldShortenErrorMessage?: boolean;
}

export const MonetaryInput = forwardRef(function MonetaryInput(
    {
        id,
        className,
        required = false,
        currencyType,
        readOnly,
        minAmount = 0,
        maxAmount,
        increment = 1,
        onBlurCapture,
        onCancel,
        onCompleted,
        customValidation,
        errorStateManager = null,
        shouldShortenErrorMessage,
        ...rest
    }: MonetaryInputProps,
    ref: React.RefCallback<HTMLInputElement> | React.MutableRefObject<HTMLInputElement>,
): React.ReactElement {
    const monetaryInputId = id || useComponentId('monetaryInput');

    const classes = Classnames('monetary-input', {
        [className]: className,
    });

    const formatValue = (value: string | number | readonly string[], returnEmptyOnZero = true) => {
        const numericValue = getNumericValue(value);

        if (!numericValue && returnEmptyOnZero) {
            return null;
        }

        // Format for input display - add $ and decimals
        const formattedInput: string = currencyDisplay(
            currencyType,
            parseFloat(convertCentsToDollars(numericValue)),
            true,
        );

        return formattedInput;
    };

    const setDefaultValue = useCallback(() => {
        return formatValue(rest.defaultValue);
    }, [rest.defaultValue]);

    const handleBlur = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const target: HTMLInputElement = evt.target as HTMLInputElement;
        const currentSelectionStart = target.selectionStart;
        const currentSelectionEnd = target.selectionEnd;
        target.value = formatValue(target.value);

        const valueLengthDiff = target.value?.length - target.dataset.oldvalue?.length;

        if (valueLengthDiff > 0) {
            setTargetSelection(
                target,
                currentSelectionStart + valueLengthDiff - 1,
                currentSelectionEnd + valueLengthDiff - 1,
            );
        } else if (valueLengthDiff < 0) {
            setTargetSelection(
                target,
                currentSelectionStart + valueLengthDiff + 1,
                currentSelectionEnd + valueLengthDiff + 1,
            );
        }

        const precedingChar = target.value.slice(target.selectionEnd - 1, target.selectionEnd);

        if (!charIsDigit(precedingChar)) {
            setTargetSelection(target, target.selectionStart - 1);
        }

        rest?.onBlur?.call(null, evt);
        target.dataset.oldvalue = target.value;
    };

    const stopEvent = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            !(
                keyboardEventHasModifier(evt) ||
                evt.key === 'Backspace' ||
                evt.key === 'ArrowLeft' ||
                evt.key === 'ArrowRight' ||
                evt.key === 'Tab'
            )
        ) {
            if (!charIsDigitOrPeriod(evt.key)) {
                return stopEvent(evt);
            }
        }

        const target: HTMLInputElement = evt.target as HTMLInputElement;

        if (!(keyboardEventHasModifier(evt) || charIsDigitOrPeriod(evt.key))) {
            const precedingChar = target.value.slice(target.selectionEnd - 1, target.selectionEnd);

            switch (evt.key) {
                case 'ArrowRight':
                    const nextChar = target.value.slice(target.selectionEnd, target.selectionEnd + 1);

                    if (!charIsDigitOrPeriod(nextChar)) {
                        setTargetSelection(target, target.selectionStart + 1);
                    }
                    break;

                case 'ArrowLeft':
                    const nextPrecedingChar = target.value.slice(target.selectionEnd - 2, target.selectionEnd - 1);

                    if (!charIsDigitOrPeriod(precedingChar)) {
                        return stopEvent(evt);
                    }

                    if (!charIsDigitOrPeriod(nextPrecedingChar) && target.selectionStart > 2) {
                        setTargetSelection(target, target.selectionStart - 1);
                    }
                    break;

                default:
                    break;
            }

            rest?.onKeyDown?.call(null, evt);
        }
    };

    const handleFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
        const target: HTMLInputElement = evt.target as HTMLInputElement;
        target.dataset.oldvalue = target.value;

        rest?.onFocus?.call(null, evt);
    };

    const printShortMessage = (inputValue: number) => {
        if (!inputValue) {
            return `Must be between ${formatValue(minAmount, false)} - ${formatValue(maxAmount, false)}`;
        }

        if (inputValue > maxAmount) {
            return `Max ${formatValue(maxAmount, false)}`;
        }

        if (inputValue < minAmount) {
            return `Min ${formatValue(minAmount, false)}`;
        }

        if (inputValue % increment != 0) {
            return 'Invalid Increment';
        }
    };

    const printLongMessage = (inputValue: number) => {
        if (!inputValue) {
            return `Amount must be greater than ${formatValue(minAmount - 1, false)} and less than ${formatValue(
                maxAmount + 1,
            )}`;
        }

        if (inputValue > maxAmount) {
            return `Amount must be less than ${formatValue(maxAmount + 1, false)}`;
        }

        if (inputValue < minAmount) {
            return `Amount must be greater than ${formatValue(minAmount - 1, false)}`;
        }

        if (inputValue % increment != 0) {
            return `Amount must be an increment of ${formatValue(increment, false)}`;
        }
    };

    const customAmountValidation = (target: HTMLInputElement): string | null => {
        const inputValue = getNumericValue(target.value);

        target.setCustomValidity('');

        if (minAmount == null && maxAmount == null) {
            return null;
        }

        if (shouldShortenErrorMessage) {
            return printShortMessage(inputValue);
        }

        if (!shouldShortenErrorMessage) {
            return printLongMessage(inputValue);
        }

        if (customValidation) {
            return customAmountValidation(target);
        }

        return null;
    };

    const isReadOnly = useMemo(() => {
        return minAmount != null && maxAmount != null && minAmount > maxAmount;
    }, [minAmount, maxAmount]);

    return (
        <>
            <ValidatedInput
                autoComplete="transaction-amount"
                className={classes}
                customValidation={customAmountValidation}
                errorStateManager={errorStateManager}
                id={monetaryInputId}
                inputMode={InputModeTypes.DECIMAL}
                name={monetaryInputId}
                onBlurCapture={onBlurCapture}
                onCancel={onCancel}
                onCompleted={onCompleted}
                placeholder="Enter an amount"
                ref={ref}
                required={required}
                testId="monetary-input-field"
                type={InputTypes.TEXT}
                {
                    ...rest /* overrides to rest must come after this */
                }
                defaultValue={setDefaultValue()}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                data-oldvalue={setDefaultValue()}
                data-min={minAmount}
                data-max={maxAmount}
                readOnly={readOnly || isReadOnly}
            />
        </>
    );
});
