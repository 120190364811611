import {
    RuleProcessingStatus,
    RuleSetDefinition,
} from '../../modules/cashback-browser-extension/content-scripts/auto-apply/rule-processing';
import { UserAndAccessTokenData } from '../../store/slices/cashback-chrome-plugin/cashback-chrome-plugin-auth-slice';
import { LoggingSubject } from '../logging/test-logging';

export enum IFrameAction {
    PO_CASHBACK_PLUS_TOGGLE_IFRAME = 'po_cashback_plus_toggle__iframe',
    PO_IFRAME_OPEN = 'POCashBackPlus_Open_Extension',
    PO_IFRAME_CLOSE = 'POCashBackPlus_Close_Extension',
    PO_IFRAME_PARTNER_DATA = 'POCashBackPlus_Partner_Exchange',
    PO_IFRAME_OFFER_DATA = 'POCashBackPlus_Offer_Data',
    PO_IFRAME_APPLY_RULES = 'POCashbackPlus_Apply_Rules',
    PO_IFRAME_APPLY_RESULT = 'POCashbackPlus_Apply_Result',
    PO_IFRAME_SEND_RULES = 'POCashBackPlus_Send_Rules',
    PO_IFRAME_SET_LOGGING = 'POCashBackPlus_Set_Logging',
    PO_IFRAME_TOKEN_BUNDLE_SAVE = 'POCashbackPlus_Token_Bundle_Save',
    PO_IFRAME_TOKEN_BUNDLE_REMOVE = 'POCashbackPlus_Token_Bundle_Remove',
    PO_IFRAME_IOS_TOKEN_BUNDLE_REQUEST = 'POCashbackPlus_IOS_Token_Bundle_Request',
    PO_IFRAME_IOS_TOKEN_BUNDLE_RESPONSE = 'POCashbackPlus_IOS_Token_Bundle_Response',
    PO_IFRAME_RESIZE = 'POCashbackPlus_iframe_resize',
    PO_WIDGET_TURBOLINK = 'POCashbackPlus_Widget_TurboLink',
    PO_IFRAME_TURBOLINK = 'POCashbackPlus_Iframe_TurboLink',
    PO_WIDGET_SDK_FLAG = 'POCashbackPlus_Widget_SDK_Flag',
    PO_IFRAME_SDK_FLAG = 'POCashbackPlus_Iframe_SDK_Flag',
    PO_IFRAME_CHECKOUT_AUTOPOP = 'POCashbackPlus_Checkout_Autopop',
}

export const togglePoIframe = async () => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_CASHBACK_PLUS_TOGGLE_IFRAME,
        },
        '*',
    );
};

export const openPoIframe = async () => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_OPEN,
        },
        '*',
    );
};

export const closePoIframe = async () => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_CLOSE,
        },
        '*',
    );
};

export const transmitOfferDataDown = async (iframe: HTMLIFrameElement, offer: any) => {
    iframe.contentWindow.postMessage(
        {
            action: IFrameAction.PO_IFRAME_OFFER_DATA,
            offer,
        },
        '*',
    );
};

export const transmitUIHeightChange = async (heightInt: number) => {
    const height = `${heightInt}px`;

    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_RESIZE,
            height,
        },
        '*',
    );
};

export const transmitPartnerData = async (partnerId: string) => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_PARTNER_DATA,
            partnerId,
        },
        '*',
    );
};

export const transmitApplyData = async (
    url: string,
    rules: RuleSetDefinition[],
    giftCardId: string,
    claimCode: string,
    pinCode: string,
) => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_APPLY_RULES,
            applyData: {
                url,
                rules,
                giftCardId,
                claimCode,
                pinCode,
            },
        },
        '*',
    );
};

export const transmitSendRules = async (url: string, rules: RuleSetDefinition[]) => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_SEND_RULES,
            applyData: {
                url,
                rules,
            },
        },
        '*',
    );
};

export const transmitApplyResult = async (iframe: HTMLIFrameElement, url: string, result: RuleProcessingStatus) => {
    iframe.contentWindow.postMessage(
        {
            action: IFrameAction.PO_IFRAME_APPLY_RESULT,
            resultData: {
                url,
                result,
            },
        },
        '*',
    );
};

export const transmitSetLogging = async (whitelist: LoggingSubject[]) => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_SET_LOGGING,
            applyData: {
                whitelist,
            },
        },
        '*',
    );
};

export const transmitTokenBundleSave = async (
    userAndAccessTokenData: UserAndAccessTokenData,
    isMobileSafari: boolean,
) => {
    const serializedTokenBundle = JSON.stringify(userAndAccessTokenData);
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_TOKEN_BUNDLE_SAVE,
            serializedTokenBundle,
            isMobileSafari,
        },
        '*',
    );
};

export const transmitTokenBundleRemove = async (isMobileSafari: boolean) => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_TOKEN_BUNDLE_REMOVE,
            isMobileSafari,
        },
        '*',
    );
};

export const transmitIosTokenBundleFetchRequest = async () => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_IOS_TOKEN_BUNDLE_REQUEST,
        },
        '*',
    );
};

// Both transmitIosTokenBundleFetchResponseForIframe and transmitIosTokenBundleFetchResponseForPopup are received in
// use-iframe-message-listener.ts, but need to be sent in different ways because of their window contexts
export const transmitIosTokenBundleFetchResponseForIframe = async (
    serializedTokenBundle: string,
    iframe: HTMLIFrameElement,
) => {
    iframe.contentWindow.postMessage(
        {
            action: IFrameAction.PO_IFRAME_IOS_TOKEN_BUNDLE_RESPONSE,
            serializedTokenBundle,
        },
        '*',
    );
};

export const transmitIosTokenBundleFetchResponseForPopup = async (serializedTokenBundle: string) => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_IFRAME_IOS_TOKEN_BUNDLE_RESPONSE,
            serializedTokenBundle,
        },
        '*',
    );
};

export const transmitTurboLinkFromWidget = async (serializedLinkTokenData: string) => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_WIDGET_TURBOLINK,
            serializedLinkTokenData,
        },
        '*',
    );
};

export const transmitTurboLinkFromContentScript = async (
    serializedLinkTokenData: string,
    iframe: HTMLIFrameElement,
) => {
    iframe.contentWindow.postMessage(
        {
            action: IFrameAction.PO_IFRAME_TURBOLINK,
            serializedLinkTokenData,
        },
        '*',
    );
};

export const transmitSdkFlagFromWidget = async () => {
    window.top.postMessage(
        {
            action: IFrameAction.PO_WIDGET_SDK_FLAG,
        },
        '*',
    );
};

export const transmitSdkFlagFromContentScript = async (iframe: HTMLIFrameElement) => {
    iframe.contentWindow.postMessage(
        {
            action: IFrameAction.PO_IFRAME_SDK_FLAG,
        },
        '*',
    );
};

export const transmitCheckoutAutopop = async (iframe: HTMLIFrameElement) => {
    iframe.contentWindow.postMessage(
        {
            action: IFrameAction.PO_IFRAME_CHECKOUT_AUTOPOP,
        },
        '*',
    );
};
