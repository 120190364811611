import { combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { commonInitState, commonSlices } from '../common';

import partnerPortalSliceReducer, { partnerPortalInitialState } from '../slices/partner-portal/partner-portal-slice';
import { portalApiSlice } from '../slices/partner-portal/partner-portal-api-slice';
import { portalAuthenticationApiSlice } from '../slices/partner-portal/partner-port-authentication-api-slice';
import { PrizeoutEnv } from '../../utils/constants/prizeout-website';

export const partnerPortalSlices = {
    partnerPortal: partnerPortalSliceReducer,
};

export const partnerPortalAPIQuerySlices = {
    [portalApiSlice.reducerPath]: portalApiSlice.reducer,
    [portalAuthenticationApiSlice.reducerPath]: portalAuthenticationApiSlice.reducer,
};

const portalReducer = combineReducers({
    ...commonSlices,
    ...partnerPortalSlices,
    ...partnerPortalAPIQuerySlices,
});

const _portalRootReducer = combineReducers({
    ...commonSlices,
    ...partnerPortalSlices,
});

export const partnerPortalStore = configureStore({
    reducer: portalReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(portalApiSlice.middleware).concat(portalAuthenticationApiSlice.middleware),
    devTools: process.env.ENV !== PrizeoutEnv.PRODUCTION,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type PartnerPortalRootState = ReturnType<typeof _portalRootReducer>;

export type AppDispatch = typeof partnerPortalStore.dispatch & ThunkDispatch<PartnerPortalRootState, null, AnyAction>;

export const PartnerPortalInitialState: PartnerPortalRootState = {
    ...commonInitState,
    partnerPortal: partnerPortalInitialState,
};
