import axios, { AxiosInstance, AxiosResponse, Method } from 'axios';
import qs from 'qs';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { jwtTokenExpirationLogging } from './jwt-token-check';

const BASE_URL = process.env.BASE_URL; // 'http://localhost:8068';
const ANALYTICS_URL = process.env.ANALYTICS_URL;

type BaseApiCall = {

    data: any;
    endpoint?: string;
};

interface MethodApiCall extends BaseApiCall {
    method: Method;
    endpoint: string;
}

interface RequestApiCall extends MethodApiCall {
    // This allows the thunk to cancel the API call if the thunk promise is
    // aborted.  This will result in the thunk being rejected and the `.rejected`
    // extra reducer being invoked.  The extra reducer will need to take the `action`
    // parameter and check its value:
    //
    //      if (action?.error?.name === 'AbortError') {
    //          return;
    //      }
    //
    // Mostly an abort should not modify the slice in any way, although there
    // could be exceptions.  Any other error should be handled as an error.
    //
    // While this is primarily provided for use in Redux thunks, this signal
    // is the same as the signal retrieved from an AbortController, so if
    // it were necessary to have a specific use of that, it could be done.
    //
    // See the docs for createAsyncThunk, AbortController, and AbortSignal for
    // more information.
    signal: AbortSignal;
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
});

const analyticsAxiosInstance: AxiosInstance = axios.create({
    baseURL: ANALYTICS_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 60000,
});

const api = async ({ data, method, endpoint }: MethodApiCall): Promise<AxiosResponse<any>> => {
    const fingerprint = (await (await FingerprintJS.load()).get()).visitorId;
    const results = await axiosInstance({
        data: method == 'POST' ? qs.stringify(data) : null,
        headers: {
            'X-Fingerprint': fingerprint,
        },
        method: method,
        params: method == 'GET' ? data : null,
        url: endpoint,
        withCredentials: true,
    });
    return results;
};

export const analyticsApi = async ({ data }: BaseApiCall): Promise<AxiosResponse<any>> => {
    const results = await analyticsAxiosInstance({
        data,
        method: 'POST',
    });
    return results;
};

class API {
    private UNAUTHORIZED_CODE = 401;

    public token = '';
    public browserExtensionTokens = {
        userToken: '',
        accessToken: '',
    };


    public unauthorizedInterceptor: () => void = () => {};

    async request({ data, method, endpoint, signal }: RequestApiCall): Promise<AxiosResponse<any>> {
        const fingerprint = (await (await FingerprintJS.load()).get()).visitorId;
        let headers: any = {
            'X-Fingerprint': fingerprint,
        };

        if (this.token) {
            jwtTokenExpirationLogging('accessToken', this.token);
            headers = {
                ...headers,
                'X-User-Access-Token': this.token,
            };
        }

        if (this.browserExtensionTokens.accessToken && this.browserExtensionTokens.userToken) {
            headers = {
                ...headers,
                'X-Browser-Extension-Access-Token': this.browserExtensionTokens.accessToken,
                'X-Browser-Extension-User-Token': this.browserExtensionTokens.userToken,
            };
        }

        const results = await axiosInstance({
            data: method == 'POST' ? qs.stringify(data) : null,
            headers,
            method: method,
            params: method == 'GET' ? data : null,
            url: endpoint,
            signal: signal,
            withCredentials: true,
        }).catch((e) => {
            const { code, message, name, response: { data = null, status = null, statusText = null } = {} } = e;
            const err = Error(message);

            if (status === this.UNAUTHORIZED_CODE) {
                this.unauthorizedInterceptor();
            }

            const newErr = {
                ...err,
                code,
                message,
                name,
                response: {
                    data,
                    status,
                    statusText,
                },
            };

            throw newErr;
        });

        if (results.status === this.UNAUTHORIZED_CODE) {
            this.unauthorizedInterceptor();
        }

        return results;
    }
}

export const sharedAPI = new API();

export default api;
