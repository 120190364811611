import { routes } from '../../../utils';

export enum EarningsEventGroups {
    EARNINGS_CASHBACK = 'CashbackEarningsData',
    EARNINGS_INSIGHTS = 'InsightsEarningsData',
}

export interface CashbackEarningsTracker {
    actualSpendEarningsInCents: number;
    estimatedSpendEarningsInCents: number;
    actualActionEarningsInCents: number;
    estimatedActionEarningsInCents: number;
    earningCurrency: string;
    earningMonth: string;
}

export enum EarningsInsightImageType {
    CATEGORY = 'category',
    REWARD = 'reward',
    URL = 'url',
    FA = 'fa-icon',
}

export interface EarningsInsight {
    cta?: routes;
    imageStr: string;
    imageType: EarningsInsightImageType;
    insightHeader: string;
    insightText?: string;
    insightType: string;
    selectMerchant?: string;
}
