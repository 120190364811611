import { combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { PrizeoutEnv } from '../../utils/constants/prizeout-website';
import { commonInitState, commonSlices } from '../common';

export const adminPortalSlices = {};

const adminPortalReducer = combineReducers({
    ...commonSlices,
    ...adminPortalSlices,
});

export const adminPortalStore = configureStore({
    reducer: adminPortalReducer,
    devTools: process.env.ENV !== PrizeoutEnv.PRODUCTION,
});

export type AdminPortalRootState = ReturnType<typeof adminPortalReducer>;

export type AppDispatch = typeof adminPortalStore.dispatch & ThunkDispatch<AdminPortalRootState, null, AnyAction>;

export const AdminPortalInitialState: AdminPortalRootState = {
    ...commonInitState,
};
