import React, { forwardRef, useState } from 'react';
import SDKMessenger from '../../../utils/services/sdk';
import { AppDispatch } from '../../../store/widget/widget';
import { Button } from './button';
import { ButtonIconPosition } from './types';
import { ButtonTrackingTypes, TrackingService } from '../../../utils/services/tracking';
import { ConfirmationModal } from '../modals/confirmation-modal';
import { resetSDKParams, selectIsGiftingMode } from '../../../store/slices/sdk-slice';
import { useAppSelector, useGoToRoute, usePersistentAccessTokens } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { logOut, resetUserSlice } from '../../../store/slices/user-slice';
import { resetPartnerConfigSlice, selectProductName } from '../../../store/slices/partner-config-slice';
import { resetVerificationSlice } from '../../../store/slices/verification-slice';
import { selectIsDTCModule, setIsHidingPrizeout } from '../../../store/slices/common-slice';
import { selectSidebarIsOpen, toggleIsSidebarOpen } from '../../../store/slices/sidebar-slice';
import { routes } from '../../../utils/helpers/routing-helpers';
import { resetWalletSlice } from '../../../store/slices/wallet-slice';

import './exit-button.scss';

interface ExitButtonProps {
    onClick?: () => void;
}

export const ExitButton = forwardRef<HTMLButtonElement, ExitButtonProps>(
    (
        { onClick },
        ref: React.RefCallback<HTMLButtonElement> | React.MutableRefObject<HTMLButtonElement>,
    ): React.ReactElement => {
        const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
        const sdkMessenger = SDKMessenger.getSDKMessengerService();
        const trackingService = TrackingService.getTrackingService();
        const dispatch = useDispatch<AppDispatch>();
        const isGiftingMode = useAppSelector(selectIsGiftingMode);
        const { goToRoute } = useGoToRoute();
        const isDTCModule = useAppSelector(selectIsDTCModule);
        const sidebarIsOpen = useAppSelector(selectSidebarIsOpen);
        const productName = useAppSelector(selectProductName);
        const [{ clearPersistentAccessTokens }] = usePersistentAccessTokens();

        const closePrizeoutGifting = () => {
            goToRoute(routes.GIFTING);
        };

        const closePrizeout = async () => {
            closeConfirmationModal();
            await dispatch(setIsHidingPrizeout(true));
            await dispatch(resetSDKParams());
            await dispatch(resetUserSlice());
            await dispatch(resetPartnerConfigSlice());
            await dispatch(resetVerificationSlice());
            await dispatch(logOut());
            await dispatch(resetWalletSlice());
            clearPersistentAccessTokens();

            if (isDTCModule) {
                goToRoute(routes.DTC_HOME);
            } else {
                await trackingService.trackHomeClickExitPrizeout('/home');
                sdkMessenger.closePrizeout();
            }
        };

        const closeConfirmationModal = () => {
            setIsConfirmationModalOpen(false);
        };

        const openConfirmationModal = () => {
            if (sidebarIsOpen) {
                dispatch(toggleIsSidebarOpen());
            }
            if (isGiftingMode) {
                return closePrizeoutGifting();
            }

            setIsConfirmationModalOpen(true);
        };

        return (
            <>
                <Button
                    ref={ref}
                    ariaLabel={`Exit ${productName}`}
                    className="exit-button"
                    icon="far fa-sign-out fa-flip-horizontal"
                    iconPosition={ButtonIconPosition.LEFT}
                    isElement
                    onClick={onClick || (() => openConfirmationModal())}
                    testId="exit-button"
                    text={isDTCModule ? 'Sign out' : 'Exit'}
                    trackingType={ButtonTrackingTypes.BUTTON_CLICK_EXIT}
                />

                <ConfirmationModal
                    cancelOnClick={() => closeConfirmationModal()}
                    confirmButtonText={isDTCModule ? 'Sign out' : `Exit ${productName}`}
                    confirmOnClick={() => closePrizeout()}
                    hasIcon={true}
                    headline={
                        isDTCModule
                            ? 'Are you sure you want to sign out?'
                            : `Are you sure you want to exit ${productName}?`
                    }
                    isConfirmationModalOpen={isConfirmationModalOpen}
                />
            </>
        );
    },
);
