import React from 'react';
import Classnames from 'classnames';
import { TypographicSizes } from '../../../store/slices/common-slice';

import './paragraph-secondary.scss';

interface ParagraphSecondaryProps {
    className?: string;
    isBold?: boolean;
    size?: TypographicSizes;
    text: string;
    id?: string; // For aria describedby if applicable
}

export const ParagraphSecondary: React.FC<ParagraphSecondaryProps> = ({
    className,
    id,
    isBold,
    size = TypographicSizes.ONE,
    text,
}): React.ReactElement => {
    const classes = Classnames(
        'paragraph-secondary',
        {
            [`paragraph-secondary--size-${size}`]: size,
            'paragraph-secondary--is-bold': isBold,
        },
        className,
    );

    return (
        <p data-testid="paragraph-secondary" id={id && id} className={classes}>
            {text}
        </p>
    );
};
