import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../widget/widget';

// Define a type for the slice state
export interface SdkState {
    sdk_settings?: SDKParams;
}

// Define the initial state using that type
export const sdkInitialState: SdkState = {};

export type SDKParams = {
    defaultSelectedOffer?: string;
    is_external_linking_enabled?: boolean;
    miniWidget?: boolean;
    partner_id: string;
    publisher?: PublisherSDKParams;
    redeem?: boolean;
    selection?: boolean;
    session_id: string;
    user?: UserSDKParams;
    widgetType?: string;
};

type UserSDKParams = {
    firstname: string;
    lastname: string;
    year_of_birth: number;
    gender: string;
    email: string;
    user_id: string;
    session_id: string;
    country: string;
    region: string;
    balance: number;
};

type PublisherSDKParams = {
    name: string;
    id: string;
    apikey: string;
};

export const sdkSlice = createSlice({
    initialState: sdkInitialState,
    name: 'sdk',
    reducers: {
        setSdkParams(state, action) {
            try {
                state.sdk_settings = JSON.parse(action.payload);
            } catch (e) {
                state.sdk_settings = action.payload;
            }

            if (state.sdk_settings.user) {
                state.sdk_settings.session_id = state.sdk_settings.user.session_id;
            }

            if (state.sdk_settings.publisher && state.sdk_settings.publisher.id) {
                state.sdk_settings.partner_id = state.sdk_settings.publisher.id;
            }
        },
        setWidgetType(state, action: PayloadAction<string>) {
            state.sdk_settings.widgetType = action.payload;
        },
        resetSDKParams() {
            return sdkInitialState;
        },
        toggleIsMiniWidget(state) {
            state.sdk_settings.miniWidget = !state.sdk_settings.miniWidget;
        },
        resetDefaultActiveOffer(state) {
            state.sdk_settings.defaultSelectedOffer = null;
        },
    },
});

export const { resetSDKParams, setSdkParams, toggleIsMiniWidget, setWidgetType, resetDefaultActiveOffer } =
    sdkSlice.actions;

const selectSDKState = ({ sdk }: RootState) => sdk;

export const getSdkParams = createSelector(selectSDKState, ({ sdk_settings }) => sdk_settings);

export const selectSentrySDKParams = createSelector(getSdkParams, (sdkParams) => {
    if (sdkParams) {
        const { partner_id, publisher, session_id } = sdkParams;
        return {
            context: {
                partner_id,
                publisher,
                session_id,
            },
            tags: {
                partner_id,
                ...publisher,
                session_id,
            },
        };
    }
});

export const selectPartnerId = createSelector(getSdkParams, (sdkParams) => sdkParams?.partner_id);

export const getUserSdkParams = createSelector(getSdkParams, (sdkParams) => sdkParams?.user);

export const selectIsMiniWidget = createSelector(getSdkParams, (sdkParams) => sdkParams?.miniWidget);

export const selectIsGiftingMode = createSelector(getSdkParams, (sdkSettings) => {
    if (sdkSettings) {
        return sdkSettings.widgetType === 'gifting';
    }
    return false;
});

export const selectIsReceiveGiftingMode = createSelector(
    getSdkParams,
    (sdkParams) => sdkParams?.widgetType === 'receive-gift',
);

export const selectDefaultSelectedOffer = createSelector(getSdkParams, (sdkParams) => sdkParams?.defaultSelectedOffer);

export const selectIsExternalLinkingEnabled = createSelector(
    getSdkParams,
    (sdkParams) => sdkParams?.is_external_linking_enabled,
);

export default sdkSlice.reducer;
