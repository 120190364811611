import { TypedUseSelectorHook, useSelector } from 'react-redux';
import type { RootState } from '../store/widget/widget';
import { CBCERootState } from '../store/cashback';
import { CPRootState } from '../store/claim-page';
import { SFRootState } from '../store/store-front';
import { AdminPortalRootState } from '../store/admin-portal';

// Use throughout your app instead of plain `useSelector`
export const useAppSelector: TypedUseSelectorHook<
    RootState | CPRootState | SFRootState | AdminPortalRootState | CBCERootState
> = useSelector;
