import { createSelector } from '@reduxjs/toolkit';
import { rewardsApiSlice } from './rewards-api-slice';
import { RootState } from '../../widget/widget';
import { RewardsCommonState } from './rewards-common-slice';

const selectGetRewardsResults = rewardsApiSlice.endpoints.getRewards.select();

export const selectGetRewardsIsLoading = createSelector(selectGetRewardsResults, ({ isLoading }) => isLoading);

export const selectGetRewards = createSelector(selectGetRewardsResults, ({ data }) => data);

export const selectAvailableRewards = createSelector(selectGetRewards, (data) => data?.available);

export const selectFirstAvailablePartnerReward = createSelector(
    selectAvailableRewards,
    (data) => data?.[0].partnerReward,
);

export const selectActiveRewards = createSelector(selectGetRewards, (data) => data?.active);

export const selectCompletedRewards = createSelector(selectGetRewards, (data) => data?.completed);

export const selectRewardsCommonState = ({ rewardsCommon }: RootState): RewardsCommonState => rewardsCommon;

export const selectCurrentConsumerReward = createSelector(
    selectRewardsCommonState,
    ({ currentConsumerReward }) => currentConsumerReward,
);

export const selectCurrentPartnerReward = createSelector(
    selectCurrentConsumerReward,
    ({ partnerReward }) => partnerReward,
);

export const selectCurrentUserReward = createSelector(selectCurrentConsumerReward, ({ userReward }) => userReward);

export const selectCurrentRewardCurrency = createSelector(
    selectCurrentUserReward,
    ({ payoutAmountCurrencyCode }) => payoutAmountCurrencyCode,
);
